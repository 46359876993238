const monitoring = {
  monitoringStatus: 'Statut',
  monitoringCreatedAt: 'Date de création',
  monitoringSubjectType: 'Type',
  monitoringResources: 'Ressources',
  monitoringId: 'ID',
  monitoringDeploymentId: 'ID du déploiement',
  monitoringExecutionId: "ID d'exécution",
  monitoringCreationDate: 'Date de création',
  monitoringEndDate: 'Date de fin',
  completionRate: 'Taux de complétion',
  batchSize: 'Taille du lot',

  executionDetails: "Détails de l'exécution",
  listOfActions: 'Liste des actions',

  monitoringDeployment: 'Deployment',
  monitoringExecution: 'Execution',

  actionResource: 'Ressource',
  actionStartDate: 'Date de début',
  actionEndDate: 'Date de fin',
  actionStatus: 'Statut',
  actionKind: 'Genre',
  actionType: "Type d'action",
  actionOptions: 'Options',

  monitoringStatusNothingToDo: 'Aucune action',
  monitoringStatusPending: 'En attente',
  monitoringStatusInProgress: 'En cours',
  monitoringStatusCompleted: 'Terminé',
  monitoringStatusFailed: 'Échoué',

  actionStatusPending: 'En attente',
  actionStatusSkipped: 'Ignoré',
  actionStatusInProgress: 'En cours',
  actionStatusFailed: 'Échoué',
  actionStatusCompleted: 'Terminé',

  actionCopyResourceId: "Copier l'ID de la resource",
  actionSeeLogs: 'Voir les logs',
  actionLogsTitle: 'Logs',

  ddlAction: 'Définition de données',
  dmlAction: 'Manipulation de données',

  sourceActionResetSourceData: 'Resource source data',
  sourceActionFetchNewSourceData: 'Fetch new source data',
  sourceActionComputeSource: 'Compute source',
  sourceActionPatchSourceData: 'Patch source data',
  sourceActionComputeSourceCheapMetrics: 'Compute source cheap metrics',
  sourceActionComputeSourceColumnExpensiveMetrics: 'Compute source expensive metrics',

  datasetActionInitializeDataset: 'Initialize dataset',
  datasetActionIntegrateSourceDataToDataset: 'Integrate source data to dataset',
  datasetActionComputeDataset: 'Compute dataset',
  datasetActionComputeDatasetCheapMetrics: 'Compute dataset cheap metrics',
  datasetActionComputeDatasetColumnExpensiveMetrics: 'Compute dataset expensive metrics',

  syncActionDeliverAllSyncRecords: 'Deliver all sync records',
  syncActionDeliverSyncRecordsIncrement: 'Deliver sync records increment',
  syncActionDeliverSpecificSyncRecords: 'Deliver specific sync records',

  analyticsSyncActionDeliverDatasetRecordsToAnalytics: 'Deliver dataset records to analytics',

  garbageCollectionActionCleanUpDataWarehouseGarbage: 'Clean up datawarehouse garbage',
  garbageCollectionActionCleanUpExecutionGarbage: 'Clean up execution garbage',
  garbageCollectionActionResetPreparedSourceData: 'Reset prepared source data',
  garbageCollectionActionCleanUpExternalDataShapeSnapshotsGarbage:
    'Clean up external data shape snapshots garbage',

  sourceActionCreateSourceResources: 'Create source resources',
  sourceActionReInitializeSourceData: 'Reinitialize source data',
  sourceActionDeleteSourceResources: 'Delete source resources',
  sourceActionCreateRawSourceColumn: 'Create raw source column',
  sourceActionDeleteRawSourceColumn: 'Delete raw source column',
  sourceActionRenameRawSourceColumn: 'Rename raw source column',
  sourceActionCreateSourceColumn: 'Create source column',
  sourceActionDeleteSourceColumn: 'Delete source column',
  sourceActionRenameSourceColumn: 'Rename source column',
  sourceActionChangeSourceColumnType: 'Change source column type',
  sourceActionRenameSource: 'Rename source',

  relationshipActionCreateSemanticRelationshipResources: 'Create semantic relationship resources',
  relationshipActionDeleteSemanticRelationshipResources: 'Delete semantic relationship resources',
  relationshipActionCreateSourceRelationshipRecords: 'Create source relationship records',
  relationshipActionDeleteSourceRelationshipRecords: 'Delete source relationship records',

  datasetActionCreateDatasetResources: 'Create dataset resources',
  datasetActionDeleteDatasetResources: 'Delete dataset resources',
  datasetActionCreateDatasetColumn: 'Create dataset column',
  datasetActionDeleteDatasetColumn: 'Delete dataset column',
  datasetActionRenameDatasetColumn: 'Rename dataset column',
  datasetActionChangeDatasetColumnType: 'Change dataset column type',
  datasetActionChangeDatasetColumnSemanticField: 'Change dataset column semantic field',
  datasetActionEnableDatasetDeduplication: 'Enable dataset deduplication',
  datasetActionDisableDatasetDeduplication: 'Disable dataset deduplication',
  datasetActionCreateDatasetForeignKey: 'Create dataset foreign key',
  datasetActionDeleteDatasetForeignKey: 'Delete dataset foreign key',
  datasetActionCreateDatasetIndex: 'Create dataset index',
  datasetActionDeleteDatasetIndex: 'Delete dataset index',
  datasetActionUpdateDatasetIndexSources: 'Update dataset index sources',
  datasetActionReInitializeDatasetData: 'Reinitialize dataset data',
  datasetActionPatchDatasetData: 'Patch dataset data',
  datasetActionComputeDatasetForeignKeys: 'Compute dataset foreign keys',
  datasetActionComputeDatasetAggregates: 'Compute dataset aggregates',

  syncActionCreateApiSyncResources: 'Create API sync resources',
  syncActionDeleteApiSyncResources: 'Delete API sync resources',
  syncActionCreateDatabaseSyncResources: 'Create database sync resources',
  syncActionDeleteDatabaseSyncResources: 'Delete database sync resources',
  syncActionCreateFileSystemSyncResources: 'Create filesystem sync resources',
  syncActionDeleteFileSystemSyncResources: 'Delete filesystem sync resources',
  syncActionCreateWebhookSyncResources: 'Create webhook sync resources',
  syncActionDeleteWebhookSyncResources: 'Delete webhook sync resources',
  syncActionRenameSync: 'Rename sync',
  syncActionChangeSyncExistingDatasetRecordsPolicy: 'Change sync existing dataset records policy',

  sourceRelationships: 'Source Relationships',
  garbageCollection: 'Garbage Collection',
  resources: 'Resources',
};

export default monitoring;
