// Importing type
import { TreeStructure } from '@components/form/items/OctoTreeSelect';
import {
  ActiveConnection,
  ActiveConnectionSettings,
  Connection,
} from '@features/connections/types';
import * as apiTypes from '../types';

import { dataWareHouseTree, integrationsTree, staticTree } from '@data/connections';

import apolloClientInstance from '@apolloClientInstance';
import { connectors } from '@data/connectors';
import {
  CREATE_CONNECTION,
  DELETE_CONNECTION,
  TEST_SETTINGS,
  UPDATE_CONNECTION,
} from '@features/connections/ducks/graphql/mutations';
import {
  GET_CONNECTION,
  GET_CONNECTIONS,
  GET_CONNECTION_RESOURCES,
  LIST_API_CONNECTION_ENTITY_TYPES,
} from '@features/connections/ducks/graphql/queries';
import { toBackendConnectionSettings } from './mappingTypes/toBackendConnectionSettings';
import { toFrontendConnectionSettings } from './mappingTypes/toFrontendConnectionSettings';
import { ConnectionReadModel } from './backendTypes/ConnectionReadModel';
import { DeleteConnectionError } from '../types';

const getConnections: apiTypes.APIGetConnections = async (accountId, abortSignal?) => {
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.query({
    query: GET_CONNECTIONS,
    variables: {
      accountId,
    },
  });
  if (res.data.connections) {
    const connections = res.data.connections.map((connection: ConnectionReadModel) => ({
      ...connection,
      settings: toFrontendConnectionSettings(connection.settings),
      connector: connectors.find(
        (connector) => connector.connectorType === connection.settings.type
      ),
    }));
    return connections;
  } else {
    throw new Error('Unexpected error');
  }
};
const getConnection: apiTypes.APIGetConnection = async (id: string) => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.query({
    query: GET_CONNECTION,
    variables: {
      id,
    },
  });

  const connectionPayload = res.data.connection;

  if (connectionPayload) {
    const connection: Connection = {
      ...connectionPayload,
      settings: toFrontendConnectionSettings(connectionPayload.settings),
      connector: connectors.find(
        (connector) => connector.connectorType === connectionPayload.settings.type
      ),
    };
    return connection;
  } else {
    throw new Error('Unexpected error');
  }
};

const updateConnection: apiTypes.APIUpdateConnection = async (connection: ActiveConnection) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const updatedConnectionDetails = {
    id: connection.id,
    name: connection.name,
    settings: toBackendConnectionSettings(connection.settings),
    canBeUsedAs: connection.canBeUsedAs,
  };
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: UPDATE_CONNECTION,
    variables: {
      command: updatedConnectionDetails,
    },
  });
  const newConnection = {
    ...res.data.updateConnection,
    // TODO @@@@koralex [will miss a settings.type for API connections?]
    settings: toFrontendConnectionSettings(res.data.updateConnection.settings),
    connector: connectors.find(
      (connector) => connector.connectorType === res.data.updateConnection.type
    ),
  };
  if (!connection) {
    error = true;
    errorDetails = 'No connections found.';
  }
  return {
    error,
    connection: newConnection,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveConnection: apiTypes.APISaveConnection = async (connection, accountId) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const connectionCommand = {
    accountId,
    name: connection.name,
    type: connection.settings?.type,
    settings: toBackendConnectionSettings(connection.settings),
    canBeUsedAs: connection.canBeUsedAs,
  };
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: CREATE_CONNECTION,
    variables: {
      command: connectionCommand,
    },
  });

  const newCreatedConnection = {
    ...res.data.createConnection,
    // TODO @@@@koralex [will miss a settings.type for API connections?]
    settings: toFrontendConnectionSettings(res.data.createConnection.settings),
    connector: connectors.find(
      (connector) => connector.connectorType === res.data.createConnection.type
    ),
  };
  if (!connection) {
    error = true;
    errorDetails = 'No connections found.';
  }
  return {
    error,
    connection: newCreatedConnection,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const deleteConnection: apiTypes.APIDeleteConnection = async (connection: Connection) => {
  const apolloClient = await apolloClientInstance();
  try {
    const res = await apolloClient.mutate({
      mutation: DELETE_CONNECTION,
      variables: {
        command: {
          id: connection.id,
        },
      },
    });
    switch (res.data?.deleteConnection?.__typename) {
      case 'ConnectionDeleted':
      case 'ConnectionNotFound':
        return {
          connection,
        };

      case 'ConnectionUsedByResource':
        return {
          connection,
          error: DeleteConnectionError.connectionUsedByResource,
        };

      default:
        console.error(`The deleteConnection mutation returned an unexpected result.`, res);
        return {
          connection,
          error: DeleteConnectionError.unexpectedError,
        }
    }
  } catch (error) {
    console.error(`The deleteConnection mutation failed.`, error);
    return {
      connection,
      error: DeleteConnectionError.unexpectedError,
    }
  }
};
const testSettings: apiTypes.APITestSettings = async (accountId: string, settings: ActiveConnectionSettings) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: TEST_SETTINGS,
    variables: {
      command: {
        type: settings.type,
        settings: toBackendConnectionSettings(settings),
        accountId,
      },
    },
  });

  if (!res.data.testSettings) {
    error = true;
    errorDetails = 'No connections found.';
  }
  return {
    error,
    status: res.data.testSettings,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getConnectionPointer: apiTypes.APIGetConnectionPointers = async (connection: Connection) => {
  let pointers: TreeStructure;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  switch (connection.connector.category) {
    case 'api':
      pointers = integrationsTree;
      break;
    case 'db':
      pointers = dataWareHouseTree;
      break;
    case 'fs':
      pointers = staticTree;
      break;
    default:
      pointers = {};
      break;
  }
  if (!connection) {
    error = true;
    errorDetails = 'No connections found.';
  }
  return {
    pointers,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const getConnectionResources: apiTypes.APIGetConnectionResources = async (query, abortSignal) => {
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.query({
    query: GET_CONNECTION_RESOURCES,
    variables: {
      query,
    },
  });

  if (res.data.getConnectionResources.__typename === 'ConnectionResourceList') {
    return res.data.getConnectionResources;
  }
  return new Error(res.data.getConnectionResources.__typename);
};

const getApiConnectionEntityTypes: apiTypes.APIGetApiDestinationEntity = async (query) => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.query({
    query: LIST_API_CONNECTION_ENTITY_TYPES,
    variables: {
      query,
    },
  });
  if (res.data.listApiConnectionEntityTypes) {
    return res.data.listApiConnectionEntityTypes;
  } else {
    throw new Error('Unexpected error');
  }
};

export {
  getConnections,
  getConnection,
  updateConnection,
  saveConnection,
  deleteConnection,
  testSettings,
  getConnectionPointer,
  getConnectionResources,
  getApiConnectionEntityTypes,
};
