import {
  ActiveConnection,
  ActiveConnectionSettings,
  Connection,
  ConnectionSettings,
  ConnectionStatus,
} from '@features/connections/types';
import { TreeStructure } from '@components/form/items/OctoTreeSelect';
import { ConnectionResource } from '../types/ConnectionResources';
import {
  ApiConnectionEntityTypeOutput,
  ListApiConnectionEntityTypesInput,
} from '../types/ApiConnectionEntity';

export interface ApiGetConnectionsPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  connections: Connection[];
}
export interface ApiGetConnectionPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  connection: Connection;
}
export interface ConnectionsReducerActions {
  type: string;
  payload: ApiGetConnectionsPayload;
}

export interface ApiSaveConnectionPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  connection: Connection;
}
export interface ConnectionReducerActions {
  type: string;
  payload: ApiSaveConnectionPayload;
}

export interface ApiDeleteConnectionPayload {
  connection: Connection;
  error?: DeleteConnectionError;
}

export enum DeleteConnectionError {
  connectionUsedByResource = 'connectionUsedByResource',
  unexpectedError = 'unexpectedError',
}

export interface DeleteConnectionReducerActions {
  type: string;
  payload: ApiDeleteConnectionPayload;
}
export interface ApiTestConnectionPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  status: ConnectionStatus;
}
export interface TestSettingsReducerActions {
  type: string;
  payload: ApiTestConnectionPayload;
}
export interface ApiGetConnectionPointersPayload {
  loaded: boolean;
  isFetching?: boolean;
  error?: boolean;
  errorDetails?: string | undefined;
  pointers: TreeStructure;
}
export interface ConnectionPointerReducerActions {
  type: string;
  payload: ApiGetConnectionPointersPayload;
}
export type APIGetConnections = (
  accountId: string,
  abortSignal?: AbortSignal
) => Promise<Connection[]>;
export type APIGetConnection = (id: string) => Promise<Connection>;
export type APIUpdateConnection = (
  connection: ActiveConnection
) => Promise<ApiSaveConnectionPayload>;
export type APISaveConnection = (
  connection: ActiveConnection,
  accountId: string
) => Promise<ApiSaveConnectionPayload>;
export type APIDeleteConnection = (connection: Connection) => Promise<ApiDeleteConnectionPayload>;
export type APITestSettings = (
  accountId: string,
  settings: ActiveConnectionSettings
) => Promise<ApiTestConnectionPayload>;
export type APIGetConnectionPointers = (
  connection: Connection
) => Promise<ApiGetConnectionPointersPayload>;

export type APIGetApiDestinationEntity = (
  getApiDestinationEntitiesInput: ListApiConnectionEntityTypesInput
) => Promise<ApiConnectionEntityTypeOutput>;

export interface GetConnectionSaga {
  type: string;
  payload: { id: string };
}
export interface UpdateConnectionSaga {
  type: string;
  payload: { connection: ActiveConnection };
}
export interface SaveConnectionSaga {
  type: string;
  payload: { connection: ActiveConnection };
}
export interface DeleteConnectionSaga {
  type: string;
  payload: { connection: Connection };
}
export interface TestSettingsSaga {
  type: string;
  payload: { accountId: string; settings: ConnectionSettings };
}
export interface GetConnectionPointersSaga {
  type: string;
  payload: { connection: Connection };
}

export type GetConnectionResourcesQuery = Readonly<{
  connectionId: string;
  groupId: string;
  parentPath?: any;
  pageSize?: number;
  pageToken?: any;
}>;

export type ConnectionResourceResult = Readonly<{
  resources: ConnectionResource[];
  nextPageToken?: any;
}>;

export type APIGetConnectionResources = (
  query: GetConnectionResourcesQuery,
  abortSignal?: AbortSignal
) => Promise<ConnectionResourceResult | Error>;
