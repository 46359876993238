import { gql } from '@apollo/client';

export const GET_ACCOUNTS = gql`
  query {
    accounts {
      id
      name
      slug
      isActive
      partnerId
      billingReportUrl
      customerKnowledgeDashboardUrl
      offerLevel
      capabilities {
        sourceCatalog
        monitoring
        syncs
        audiences
        connections
        billing
        dashboard
        datasets
        settings
        newSyncs
        accountResourcesExecutionsMonitoring
        dataModel
      }
    }
  }
`;
