import { ActiveSourceSettings } from '@features/audiences/types';
import { ConnectorType } from '@features/connections/types';
import { SourceFileProcessingSettings, FileType, ISourceSettings } from '../../audienceBackTypes/source';
import { caseNever } from '@utils/case-never';
import { ActiveSourceFileProcessingSettings } from '../../../../../audiences/types/ActiveAudience/ActiveSourceSettings';
import { SourceSettingsType } from '../../../api/types';

export const mappingSourceSettingsProcessingPropertyToFront = (
  processing: SourceFileProcessingSettings
): ActiveSourceFileProcessingSettings | undefined => {
  switch (processing.type) {
    case FileType.csv:
      return {
        type: FileType.csv,
        delimiter: processing.delimiter,
        escape: processing.escape,
        quote: processing.quote,
      };
    case FileType.json:
      return {
        type: FileType.json,
        pattern: processing.pattern,
      };
    default:
      caseNever(processing);
  }
};
export const mappingSourceSettingsProperty = (
  sourceSettings: ISourceSettings
): ActiveSourceSettings => {
  switch (sourceSettings.type) {
    case ConnectorType.bigQuery:
      return {
        type: ConnectorType.bigQuery,
        datasetId: sourceSettings.datasetId,
        tableId: sourceSettings.tableId,
        location: sourceSettings.location,
        fullModeFilter: sourceSettings.fullModeFilter,
        incrementalModeFilter: sourceSettings.incrementalModeFilter,
      };
    case ConnectorType.mysql:
      return {
        type: ConnectorType.mysql,
        schemaName: sourceSettings.schemaName,
        objectName: sourceSettings.objectName,
      };
    case ConnectorType.postgres:
      return {
        type: ConnectorType.postgres,
        schemaName: sourceSettings.schemaName,
        tableName: sourceSettings.tableName,
      };
    case ConnectorType.sftp:
      const processing = mappingSourceSettingsProcessingPropertyToFront(sourceSettings.processing);
      if (!processing) {
        throw new Error('Processing type is missing in mapping to front');
      }
      return {
        processing,
        type: ConnectorType.sftp,
        filesMatchPattern: sourceSettings.filesMatchPattern || '',
        filesMatchPatternOptions: sourceSettings.filesMatchPatternOptions || '',
        directoryPath: sourceSettings.directoryPath || '',
        ignoredDirectories: sourceSettings.ignoredDirectories,
        modifiedAtColumn: sourceSettings.modifiedAtColumn,
        sourceType: sourceSettings.sourceType,
      };
    case ConnectorType.snowflake:
      return {
        type: ConnectorType.snowflake,
        tableName: sourceSettings.tableName,
        schemaName: sourceSettings.schemaName,
      };

    case SourceSettingsType.api: {
      return {
        ...sourceSettings,
        entitySettings: sourceSettings.entitySettings ?? {},
      };
    }

    default:
      return {} as ActiveSourceSettings;
  }
};
