import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getLatestToken } from '@providers/Auth0Provider';
import {
  getFrontendTestingSignInRequest,
  hasFrontendTestingSignInRequest,
} from '@features/frontend-e2e-testing/frontend-testing';

const apolloClientInstance = async ({
  abortSignal,
  traceparent,
}: { abortSignal?: AbortSignal; traceparent?: string } = {}) => {
  try {
    let accessToken: string | null;
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URI,
      fetchOptions: { signal: abortSignal },
      headers: traceparent
        ? {
            traceparent: traceparent,
          }
        : undefined,
    });

    if (hasFrontendTestingSignInRequest()) {
      const request = getFrontendTestingSignInRequest();
      if (request) {
        accessToken = request.accessToken;
      }
    } else {
      try {
        accessToken = await getLatestToken({
          authorizationParams: { scope: 'openid offline_access' },
        });
      } catch (error) {
        console.log('Unable to get an access token silently', error);
      }
    }

    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      };
    });

    const apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    return apolloClient;
  } catch (error) {
    const token = await getLatestToken();
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URI,
      fetchOptions: { signal: abortSignal },
      headers: traceparent
        ? {
            traceparent: traceparent,
          }
        : undefined,
    });
    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    const apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    return apolloClient;
  }
};
export default apolloClientInstance;
