import '@styles/layouts/auth.less';
import React, { Suspense, useContext, useMemo } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import ScreenSpinner from '@components/screenSpinner';
import { appRoutesEnum, authRoutesEnum, layoutRoutesEnum, pathParamKeysEnum } from './types';
import { ProtectedRoute } from './ProtectedRoute';
import { FrontendTestingSignInPage } from '@features/frontend-e2e-testing/frontend-testing';
import { useAuth0 } from '@providers/Auth0Provider';
import { ProfileContext } from '@providers/ProfileContextProvider';
import NotFound from '@components/notFound';
import { NotFoundType } from '@components/notFound/types';
import { FeatureEnum, Features } from '@features/app/types';
import { extractObjectKeys, generateId } from '@utils/helpers';
import { lazyWithRetry } from '@utils/lazy-with-retry';
const LogoutScreen = React.lazy(() => import('@screens/auth/logout'));
const AccountSelectorScreen = React.lazy(() => import('@screens/auth/accountSelector'));
const MainScreen = React.lazy(() => import('@screens/main/main'));
const OidcNEONScreen = React.lazy(() => import('@screens/oidc/OidcNEONScreen'));

const NotFoundScreen = React.lazy(() => import('@screens/notFound/NotFound'));
const AppLayoutView = React.lazy(() => import('@layout/AppLayout'));
const DataWareHouseConfig = React.lazy(
  () => import('@screens/dataWarehouse/DataWarehouseSettings')
);

const Billing = lazyWithRetry(() => import('@screens/billing'));
const Dashboard = lazyWithRetry(() => import('@screens/dashboard'));
const Monitoring = lazyWithRetry(() => import('@screens/monitoring'));
const AccountResourcesExecutionsMonitoring = lazyWithRetry(
  () => import('@screens/accountResourcesMonitoring')
);

const SourceCatalog = lazyWithRetry(() => import('@screens/sourceCatalog'));
const SourceCatalogWrapper = lazyWithRetry(() => import('@screens/sourceCatalog/wrapper'));
const SourceCatalogCreate = lazyWithRetry(() => import('@screens/sourceCatalog/create'));
const SourceCatalogEdit = lazyWithRetry(() => import('@screens/sourceCatalog/edit'));
const Connections = lazyWithRetry(() => import('@screens/connections'));
const Audiences = lazyWithRetry(() => import('@screens/audiences/list'));
const AudienceCreate = lazyWithRetry(() => import('@screens/audiences/create'));
const AudiencesArchitecture = lazyWithRetry(() => import('@screens/audiences/architecture'));
const SqlAudienceView = lazyWithRetry(() => import('@screens/audiences/sqlAudienceView'));
const AudiencePrepareObject = lazyWithRetry(() => import('@screens/audiences/prepareObject'));
const AudienceEditObject = lazyWithRetry(() => import('@screens/audiences/editObject'));

const Settings = lazyWithRetry(() => import('@screens/settings/settings'));

const SettingsWrapper = lazyWithRetry(() => import('@screens/settings/wrapper'));

const Syncs = lazyWithRetry(() => import('@screens/syncs/list'));
const SyncsCreate = lazyWithRetry(() => import('@screens/syncs/create'));
const SyncsEdit = lazyWithRetry(() => import('@screens/syncs/edit'));

const DatasetListing = lazyWithRetry(() => import('@screens/dataset/listing'));
const DatasetWrapper = lazyWithRetry(() => import('@screens/dataset/wrapper'));
const DatasetBuilder = lazyWithRetry(() => import('@screens/dataset/builder'));

const NewSyncs = lazyWithRetry(() => import('@screens/newSyncs'));
const NewSyncsWrapper = lazyWithRetry(() => import('@screens/newSyncs/wrapper'));
const NewSyncsBuilder = lazyWithRetry(() => import('@screens/newSyncs/builder'));

const AccountManagement = lazyWithRetry(() => import('@screens/management'));
const SQLAudience = lazyWithRetry(() => import('@screens/audiences/SQLAudience'));

interface IAppRoutesDetails {
  featureName: Features;
  path: appRoutesEnum;
  component: React.FC;
  wrapperComponent?: React.FC<{ children: React.ReactNode }>;
  exact?: boolean;
  children?: {
    path: appRoutesEnum;
    component: React.FC;
    exact?: boolean;
  }[];
}

const appRoutesDetails: IAppRoutesDetails[] = [
  {
    featureName: 'billing',
    path: appRoutesEnum.BILLING,
    component: Billing,
  },
  {
    featureName: 'dashboard',
    path: appRoutesEnum.DASHBOARD,
    component: Dashboard,
  },
  {
    featureName: 'monitoring',
    path: appRoutesEnum.MONITORING,
    component: Monitoring,
  },
  {
    featureName: 'accountResourcesExecutionsMonitoring',
    path: appRoutesEnum.ACCOUNT_RESOURCES_EXECUTIONS_MONITORING,
    component: AccountResourcesExecutionsMonitoring,
  },
  {
    featureName: FeatureEnum.settings,
    path: appRoutesEnum.SETTINGS,
    component: Settings,
    wrapperComponent: SettingsWrapper,
    exact: false,
  },
  {
    featureName: 'sourceCatalog',
    path: appRoutesEnum.SOURCE_CATALOG,
    component: SourceCatalog,
    wrapperComponent: SourceCatalogWrapper,
    children: [
      {
        path: appRoutesEnum.SOURCE_CATALOG_CREATE,
        component: SourceCatalogCreate,
      },
      {
        path: appRoutesEnum.SOURCE_CATALOG_EDIT,
        component: SourceCatalogEdit,
      },
    ],
  },

  {
    featureName: 'connections',
    path: appRoutesEnum.CONNECTIONS,
    component: Connections,
  },
  {
    featureName: 'datasets',
    path: appRoutesEnum.DATASETS,
    component: DatasetListing,
    wrapperComponent: DatasetWrapper,
    children: [
      {
        path: appRoutesEnum.DATASETS_PREPARE_DATASET,
        component: DatasetBuilder,
      },
      {
        path: appRoutesEnum.DATASETS_EDIT_DATASET,
        component: DatasetBuilder,
      },
    ],
  },

  {
    featureName: 'newSyncs',
    path: appRoutesEnum.NEW_SYNCS,
    component: NewSyncs,
    wrapperComponent: NewSyncsWrapper,
    children: [
      {
        path: appRoutesEnum.NEW_SYNCS_CREATE,
        component: NewSyncsBuilder,
      },
      {
        path: appRoutesEnum.NEW_SYNCS_EDIT,
        component: NewSyncsBuilder,
      },
    ],
  },
  {
    featureName: 'audiences',
    path: appRoutesEnum.AUDIENCES,
    component: Audiences,
    children: [
      {
        path: appRoutesEnum.AUDIENCES_ARCHITECTURE,
        component: AudiencesArchitecture,
      },
      {
        path: appRoutesEnum.AUDIENCES_CREATE,
        component: AudienceCreate,
      },

      {
        path: appRoutesEnum.AUDIENCE_SQL_VIEW,
        component: SqlAudienceView,
      },
      {
        path: appRoutesEnum.AUDIENCES_EDIT_DATA,
        component: AudienceEditObject,
      },
      {
        path: appRoutesEnum.AUDIENCES_PREPARE_DATA,
        component: AudiencePrepareObject,
      },
      {
        path: appRoutesEnum.SQL_AUDIENCES,
        component: SQLAudience,
      },
    ],
  },
  {
    featureName: 'syncs',
    path: appRoutesEnum.SYNCS,
    component: Syncs,
    children: [
      {
        path: appRoutesEnum.SYNCS_CREATE,
        component: SyncsCreate,
      },
      {
        path: appRoutesEnum.SYNCS_EDIT,
        component: SyncsEdit,
      },
    ],
  },
  {
    featureName: 'accountManagement',
    path: appRoutesEnum.ACCOUNT_MANAGEMENT,
    component: AccountManagement,
  },
  {
    featureName: 'destinations',
    path: appRoutesEnum.DESTINATION,
    component: () => <NotFound type={NotFoundType.underConstruction} />,
  },
];

const PlatformRoutesV2 = () => {
  const { loading } = useAuth0();
  const { visibleFeatures, accessibleFeatures, rights } = useContext(ProfileContext);

  const profileFeatures = useMemo(() => {
    const profileFeatures: string[] = [];
    const features = extractObjectKeys(rights);
    features.forEach((feature) => {
      if (rights[feature].length) {
        profileFeatures.push(feature);
      }
    });
    return profileFeatures;
  }, [rights]);

  const renderComponent = (featureName: Features, Component: React.ComponentType<{}>) => {
    if (!visibleFeatures.includes(featureName)) {
      return <NotFound type={NotFoundType.hiddenFeature} />;
    }
    if (!accessibleFeatures.includes(featureName)) {
      return <NotFound type={NotFoundType.inaccessibleFeature} />;
    }
    if (!profileFeatures) {
      return <React.Fragment></React.Fragment>;
    }
    return <Component />;
  };

  if (loading) {
    return <ScreenSpinner />;
  }

  return (
    <Suspense fallback={<ScreenSpinner />}>
      <Routes>
        <Route path={layoutRoutesEnum.MAIN} element={<ProtectedRoute component={MainScreen} />} />
        <Route
          path={authRoutesEnum.ACCOUNT_SELECTOR}
          element={<ProtectedRoute component={AccountSelectorScreen} />}
        />
        <Route
          path={`${layoutRoutesEnum.APP}/:${pathParamKeysEnum.ACCOUNT_ID}${appRoutesEnum.CREATE_DATAWAREHOUSE}`}
          element={<ProtectedRoute component={DataWareHouseConfig} />}
        />
        <Route element={<ProtectedRoute component={AppLayoutView} />}>
          {appRoutesDetails.map((parentRoute) => {
            const parentRoutePath: string = `${layoutRoutesEnum.APP}/:${pathParamKeysEnum.ACCOUNT_ID}${parentRoute.path}`;
            return (
              <Route
                key={parentRoutePath}
                path={parentRoutePath}
                element={
                  <Suspense fallback={<ScreenSpinner />}>
                    {parentRoute.wrapperComponent ? (
                      <parentRoute.wrapperComponent>{<Outlet />}</parentRoute.wrapperComponent>
                    ) : (
                      <Outlet />
                    )}
                  </Suspense>
                }
              >
                <Route
                  key={generateId()}
                  path={parentRoutePath}
                  element={renderComponent(parentRoute.featureName, parentRoute.component)}
                />
                {(parentRoute.children || []).map((childrenRoute) => {
                  return (
                    <Route
                      key={generateId()}
                      path={`${layoutRoutesEnum.APP}/:${pathParamKeysEnum.ACCOUNT_ID}${childrenRoute.path}`}
                      element={renderComponent(parentRoute.featureName, childrenRoute.component)}
                    />
                  );
                })}
              </Route>
            );
          })}
        </Route>

        <Route path="/testing/sign-in/:payload" element={<FrontendTestingSignInPage />} />

        <Route path={'/oauth/neon'} element={<OidcNEONScreen />} />

        <Route path={authRoutesEnum.LOGOUT} element={<LogoutScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </Suspense>
  );
};

export default PlatformRoutesV2;
